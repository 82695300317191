// /** @type {import('twind').Configuration} */

export default {
    theme: {
        fontFamily: {
            sans: ['Inter', 'Calibre', 'sans-serif'],
            serif: ['Merriweather', 'Georgia', 'serif'],
            body: ['Inter', 'Calibre', 'sans-serif']
        },
        colors: {
            transparent: 'transparent',
            current: 'currentColor',
            white: '#ffffff',
            black: '#000000',
            blue: {
                50: '#E9F1FF',
                200: '#BFD4FE',
                400: '#87ADFE',
                500: '#4f77ff',
                600: '#335DEC',
                700: '#1F49D8'
            },
            sky: {
                200: '#BAE6FD',
                700: '#0369A1'
            },
            gray: {
                50: '#F8FAFB',
                100: '#F1F4F7',
                200: '#E5EAF0',
                300: '#D4DCE3',
                400: '#B1BCC9',
                500: '#8895A4',
                600: '#667180',
                700: '#414D5A',
                800: '#29313E',
                900: '#10141D'
            },
            cyan: {
                500: '#5CC2D4'
            },
            stone: {
                200: '#E7E5E4'
            },
            rose: {
                50: '#FFF1F2',
                200: '#FECDD3',
                300: '#FDA4AF',
                400: '#FB7185',
                500: '#F43F5E',
                700: '#BE123C',
                800: '#8d122d'
            },
            amber: {
                50: '#FFFBEB',
                100: '#FEF1C3',
                200: '#FDE68A',
                300: '#FCD34D',
                400: '#FBBF24',
                500: '#F59E0B',
                700: '#B45309'
            },
            pink: {
                300: '#F9A8D4',
                500: '#E64C8E'
            },
            purple: {
                500: '#b05ce4'
            },
            teal: {
                400: '#2DD4BF',
                500: '#28B2AD',
                800: '#0D9488'
            },
            indigo: {
                200: '#C7D2FE'
            },
            emerald: {
                50: '#ECFDF5',
                200: '#A7F3D0',
                500: '#10B981',
                600: '#059669',
                700: '#00784B',
                800: '#25614E'
            }
        },
        extend: {
            screens: {
                xs: '450px',
                md: '768px',
                standalone: { raw: '(display-mode:standalone)' }
            },
            fontSize: {
                '2xs': ['0.65rem'],
                xsm: ['0.8125rem']
            },
            boxShadow: {
                'inner-2': 'inset 0 3px 5px rgb(0 0 0 / 13%)'
            },
            // For vh, "max-h-[80vh]" doesn't work so
            // we have to add custom heights here
            maxHeight: {
                '34vh': '34vh',
                '80vh': '80vh'
            },
            cursor: {
                grab: 'grab'
            },
            transitionProperty: {
                width: 'width'
            }
        }
    }
};
