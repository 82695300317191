/* eslint-disable no-useless-escape,import/no-unresolved,react/no-danger */
import { useEffect } from 'react';
import Head from 'next/head';
import config from 'lib/config';
import withTwindApp from '@twind/next/app';
import twindConfig from 'twind.config';
import sentryInitialize from 'lib/sentry-initialize';
import globalAppStyles from './app.global.scss?type=global';

// Initialize sentry.io prior to the app mounting.
sentryInitialize();

function PastelApp({ Component, pageProps }) {
    /* Update body background color depending on if user is 
	logged-in or not or on a project page
	TODO: Figure out a way to make this work when we remove SCSS
	because pages like billing, rewards, profile need a gray bg */
    if (typeof window !== 'undefined') {
        const { body } = document;
        const user = pageProps.initialState?.auth?.user;

        useEffect(() => {
            if (user || window.location.href.includes('project')) {
                body.classList.remove('bg-gray-100');
                body.classList.add('bg-white');
                return;
            }
            body.classList.remove('bg-white');
            body.classList.add('bg-gray-100');
        }, [user]);
    }

    return (
        <>
            <Head>
                <meta charSet="utf-8" />
                <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
                <meta
                    name="viewport"
                    content="width=device-width, initial-scale=1, maximum-scale=1, user-scalable=no"
                />
                <meta
                    name="keywords"
                    content="feedback,website,share,link,live,visual,comment,sticky,note,pin,server,web"
                />
                {config.env === 'production' && config.analytics.enabled && (
                    <script
                        type="text/javascript"
                        async
                        src="https://www.googletagmanager.com/gtag/js?id=G-HWBE2M8N83"
                    />
                )}
                {config.env === 'production' && config.analytics.enabled && (
                    <script
                        type="text/javascript"
                        dangerouslySetInnerHTML={{
                            __html: `window.dataLayer = window.dataLayer || [];
                            function gtag(){dataLayer.push(arguments);}
                            gtag('js', new Date());
                          
                            gtag('config', 'G-HWBE2M8N83');`
                        }}
                    />
                )}
                {config.env === 'production' && config.analytics.enabled && (
                    <script
                        type="text/javascript"
                        dangerouslySetInnerHTML={{
                            __html: `!function(f,b,e,v,n,t,s)
                    {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
                        n.callMethod.apply(n,arguments):n.queue.push(arguments)};
                        if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
                        n.queue=[];t=b.createElement(e);t.async=!0;
                        t.src=v;s=b.getElementsByTagName(e)[0];
                        s.parentNode.insertBefore(t,s)}(window,document,'script',
                    'https://connect.facebook.net/en_US/fbevents.js');
                    fbq('init', '752412421560651');
                    fbq('track', 'PageView');`
                        }}
                    />
                )}
                {config.env === 'production' && config.analytics.enabled && (
                    <noscript
                        dangerouslySetInnerHTML={{
                            __html: `<img height="1" width="1" src="https://www.facebook.com/tr?id=752412421560651&ev=PageView&noscript=1">`
                        }}
                    />
                )}
                {config.env === 'production' && config.analytics.enabled && (
                    <script
                        type="text/javascript"
                        dangerouslySetInnerHTML={{
                            __html: `!function(w,d,i,s){function l(){if(!d.getElementById(i)){var f=d.getElementsByTagName(s)[0],e=d.createElement(s);e.type="text/javascript",e.async=!0,e.src="https://canny.io/sdk.js",f.parentNode.insertBefore(e,f)}}if("function"!=typeof w.Canny){var c=function(){c.q.push(arguments)};c.q=[],w.Canny=c,"complete"===d.readyState?l():w.attachEvent?w.attachEvent("onload",l):w.addEventListener("load",l,!1)}}(window,document,"canny-jssdk","script");`
                        }}
                    />
                )}
                {config.env === 'production' && config.analytics.enabled && (
                    <script
                        id="profitwell-js"
                        data-pw-auth="ea2c520e57ef32e85c1ca62d1654e670"
                        type="text/javascript"
                        dangerouslySetInnerHTML={{
                            __html: `(function(i,s,o,g,r,a,m){i[o]=i[o]||function(){(i[o].q=i[o].q||[]).push(arguments)};
                        a=s.createElement(g);m=s.getElementsByTagName(g)[0];a.async=1;a.src=r+'?auth='+
                        s.getElementById(o+'-js').getAttribute('data-pw-auth');m.parentNode.insertBefore(a,m);
                        })(window,document,'profitwell','script','https://public.profitwell.com/js/profitwell.js');`
                        }}
                    />
                )}
            </Head>
            <>
                <style jsx global>
                    {globalAppStyles}
                </style>
                {config.env === 'production' && (
                    <>
                        <script
                            type="text/javascript"
                            // eslint-disable-next-line react/no-danger
                            dangerouslySetInnerHTML={{
                                __html: `var widgetId = "ss-widget",
                            doc = document,
                            win = window;
                          win.SS_WIDGET_TOKEN = "ntt-083c55";
                          win.SS_ACCOUNT = "usepastel.surveysparrow.com";
                          win.SS_SURVEY_NAME = "Pastel NPS";
                          if (!doc.getElementById(widgetId)) {
                            var SparrowLauncher = function () {
                              SparrowLauncher.update(arguments);
                            };
                            SparrowLauncher.args = [];
                            SparrowLauncher.update = function (e) {
                              SparrowLauncher.args.push(e);
                            };
                            win.SparrowLauncher = SparrowLauncher;
                          }`
                            }}
                        />
                        <script
                            type="text/javascript"
                            id="ss-widget"
                            async
                            src="https://usepastel.surveysparrow.com/nps/widget/ntt-083c55"
                        />
                    </>
                )}
            </>
            <Component {...pageProps} />
        </>
    );
}

export default withTwindApp(twindConfig, PastelApp);
