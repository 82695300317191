import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import config from 'lib/config';

let initialized = false;

export default function sentryInitialize() {
    if (initialized || !config.sentryEndpoint) {
        return;
    }

    Sentry.init({
        dsn: config.sentryEndpoint,
        integrations: [new BrowserTracing()],
        tracesSampleRate: 0.02,
        release: config.release
    });

    initialized = true;
}
